<template>
  <q-layout view="hHh lpR fFf" class="app_bg canna_pd_Page">
    <q-header class="canna_header_bg noselect" :style="(useHeaderWx!=='')? 'width:' +  useHeaderWx + ';' : ''">
      <q-toolbar>
        <q-toolbar-title class="header_title">
          Canna
        </q-toolbar-title>
      </q-toolbar>
    </q-header>
    <q-page-container>
      <keep-alive :exclude="['newCuenta_Main']">
        <router-view />
      </keep-alive>
    </q-page-container>
    <div style="position:fixed;right: 26px;bottom: 15px;z-index: 2;">
      <whatsapp-button size="50px" />
    </div>
    
  </q-layout>
</template>
<script>
import { requestPack } from './../boot/axios' // APP code

import myplatform_Mixin from './../mixins/platformMix.js'
import logOut_Mixin from './../mixins/requestMix.js'

export default {
  name: 'planes_main_layout',
  mixins: [
    logOut_Mixin,
    myplatform_Mixin
  ],
  components: {
    // cxIcon: require('components/appIcons.vue').default
    whatsappButton: require('components/whatsapp-button.vue').default
  },
  data () {
    return {
      a_name: 'layout basico canna 201',
      version: '201',
      info: {
        data: []
      }, 
      headLoginW:''
    }
  },
  computed: {
    appLogin () {
      return this.$store.state.myapp.appHasLogin
    },
    profile_foto () {
      if (this.$store.state.myapp.appUserProfile.hasOwnProperty('imagenFoto')){ 
        return this.$store.state.myapp.appUserProfile.imagenFoto
      } else { 
        return ''
      }
    },
    routeName () {
      return this.$route.name
    },
    profile_abrev () {
      return this.$store.state.myapp.appUserProfile.abrevName || '' 
    },
    useHeaderWx (){
      if (this.routeName==='master_inicio'){
        return (this.is_Mobile || this.$q.screen.lt.md) ? '' : (this.headLoginW===undefined || this.headLoginW===null || this.headLoginW==='') ? '' : this.headLoginW
      } else {
        return ''
      }
    }
  },
  watch:{
    headLoginW: function (newVal, oldVal){ 
      if(newVal !== oldVal){ 
        this.$forceUpdate
      }
    }
  }, 
  methods: { 
    onClickHeaderBell: function () { 
      this.$cannaDebug('-- PV2 -- Main Layout -- bell clicked')
    }
  }
}
</script>